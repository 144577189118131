import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="App">
      <Button variant="primary" onClick={handleShow}>
        Launch Offcanvas
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some placeholder text. You can put any content here, such as forms, lists, or anything you like.
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default App;
